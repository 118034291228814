import './App.css';
import Import from './ImportCSV';
function App() {
  return (
    <div className="App">
      <header className="App-header">
       
       <Import/>
       
      </header>
    </div>
  );
}

export default App;
