import React, { Component } from 'react'
import axios from 'axios';


export default class CSVReader1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loaded: false,
            name: null,
            prefix: null,
            direction: null,
            submitted: false
        }
    }


    handleName = (event) => {
        this.setState({
            name: event.target.value
        })
    }
    handlePrefix = (event) => {
        this.setState({
            prefix: event.target.value
        })
    }
    handleDirection = (event) => {
        this.setState({
            direction: event.target.value
        })
    }

    onSubmit = (event) => {
        event.preventDefault();
        var file = event.target.uploadFile.files[0]
        let formData = new FormData()
        formData.append('file', file)
        console.log(formData)
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }

        axios.post('https://mastercall.vcenter.ro:3333/uploadCSV?direction=' + this.state.direction + '&prefix=' + this.state.prefix + '&name=' + this.state.name, formData)
            .then(resp => this.setState({
                submitted: true
            }))

    }
    download = () => {
        window.open('https://mastercall.vcenter.ro:3333/getCSV?direction=' + this.state.direction + '&prefix=' + this.state.prefix + '&name=' + this.state.name, '_blank')
    }

    render() {
        return (
            <>
                <h5>File Upload</h5>
                {this.state.submitted === false ?
                    <div>
                        <form className="upload" onSubmit={this.onSubmit}>
                            <input id="nume" type="text" placeholder="Nume Campanie" style={{ width: "200px", height: "50px" }} onChange={this.handleName}></input><br></br>
                            <input id="prefix" type="text" placeholder="Prefix" style={{ width: "200px", height: "50px" }} onChange={this.handlePrefix}></input><br></br>
                            <select name="direction" id="direction" style={{ width: "207px", height: "50px" }} onChange={this.handleDirection}>
                                <option value="-">-</option>
                                <option value="PowerDialer">PowerDialer</option>
                                <option value="Preview">Preview</option>
                            </select>
                            <br></br>
                            <input type="file" name="uploadFile" accept=".csv" required />
                            <br /><br />
                            <input type="submit" onClick={this.Clicked} />
                        </form>

                        <div></div>
                    </div>
                    :
                    // <CSVLink data={this.state.newCSVData}>Download me</CSVLink>
                    <div>
                        <button onClick={this.download}>Download</button>
                    </div>
                }
            </>
        )
    }
}